import { currencyFormatter } from 'utils/currency';
import { Divider, Flex, HStack, Text } from '@chakra-ui/react';
import { planPaymentMode } from 'utils/enums';
import { useSelector } from 'react-redux';
import { selectPaymentMode } from 'redux/features/plans/plansSlice';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';

function OneTimeSummary({ amount, complete = false, border = true }) {
  return (
    <>
      <Flex
        className={`flex-col gap-1 text-base ${
          border && 'p-4 rounded-lg border border-slate-200 border-solid'
        }`}
      >
        {/* due now text */}
        <Text className='font-bold mb-1'>
          {complete ? 'Paid Upfront' : 'Due Today'}
        </Text>
        <Text className='mb-4'>
          {complete
            ? 'This was the amount you were charged when you completed your payment.'
            : 'This is the amount you will be charged today when you complete your payment. If your application is not approved, any payment made will be refunded to you.'}
        </Text>

        {/* one time payment */}
        <HStack className='w-full justify-between'>
          <Text>One-Time Payment</Text>
          <Text> {amount}</Text>
        </HStack>

        {/* tax */}
        <HStack className='w-full justify-between'>
          <Text>Tax</Text>
          <Text>$0.00</Text>
        </HStack>

        {/* spacer */}
        <Divider className='border-slate-300' />

        {/* Total */}
        <HStack className='w-full justify-between font-bold'>
          <Text>Total</Text>
          <Text>{amount}</Text>
        </HStack>
      </Flex>

      {/* Due monthly payment  information */}
      <Flex
        className={`flex-col gap-1 text-base ${
          border && 'p-4 rounded-lg border border-slate-200 border-solid'
        }`}
      >
        {/* title */}
        <Text className='font-bold mb-1'>Due Monthly</Text>
        <Text className='mb-4'>
          {complete
            ? 'This is the recurring amount you will be charged monthly once your plan is active, until the end date of your plan.'
            : 'This is the recurring amount you will be charged monthly once your plan is active, until the end date of your plan.'}
        </Text>

        <HStack className='w-full justify-between'>
          <Text>No Payment Due</Text>
          <Text>$0.00</Text>
        </HStack>

        {/* tax */}
        <HStack className='w-full justify-between'>
          <Text>Tax</Text>
          <Text>$0.00</Text>
        </HStack>

        {/* spacer */}
        <Divider bclassName='border-slate-300' />

        {/* Total */}
        <HStack className='font-bold w-full justify-between'>
          <Text>Total</Text>
          <Text>$0.00</Text>
        </HStack>
      </Flex>
    </>
  );
}

function MonthlySummary({ amount, endDate, complete = false, border = true }) {
  return (
    <>
      <Flex
        className={`flex-col gap-2 ${
          border && 'p-4 rounded-lg border border-slate-200 border-solid'
        }`}
      >
        {/* due now text */}
        <Text className='text-lg font-bold mb-1'>
          {complete ? 'Paid Upfront' : 'Due Today'}
        </Text>
        <Text className='mb-4'>
          {complete
            ? 'This was the amount you were charged when you completed your payment.'
            : 'This is the amount you will be charged today when you complete your payment. If your application is not approved, any payment made will be refunded to you.'}
        </Text>

        {/* one time payment */}
        <HStack className='w-full justify-between'>
          <Text>First Monthly Payment</Text>
          <Text> {amount}</Text>
        </HStack>

        {/* tax */}
        <HStack className='w-full justify-between'>
          <Text>Tax</Text>
          <Text>$0.00</Text>
        </HStack>

        {/* spacer */}
        <Divider className='border-slate-300' />

        {/* Total */}
        <HStack className='w-full justify-between'>
          <Text fontSize='16px' fontWeight={700}>
            Total
          </Text>
          <Text fontSize='16px' fontWeight={700}>
            {amount}
          </Text>
        </HStack>
      </Flex>

      {/* Due monthly payment  information */}
      <Flex
        className={`flex-col gap-2 ${
          border && 'p-4 rounded-lg border border-slate-200 border-solid'
        }`}
      >
        {/* title */}
        <Text className='font-bold mb-1'>Due Monthly</Text>
        <Text className='mb-4'>
          {complete
            ? 'This is the recurring amount you will be charged monthly once your plan is active, until the end date of your plan.'
            : 'This is the recurring amount you will be charged monthly once your plan is active, until the end date of your plan.'}
        </Text>

        <HStack className='w-full justify-between'>
          <Text>Recurring Monthly Payment</Text>
          <Text>{amount}</Text>
        </HStack>

        {/* tax */}
        <HStack className='w-full justify-between'>
          <Text>Tax</Text>
          <Text>$0.00</Text>
        </HStack>

        {/* spacer */}
        <Divider className='border-slate-300' />

        {/* Total */}
        <HStack className='w-full justify-between'>
          <Text fontSize='16px' fontWeight={700}>
            Total
          </Text>
          <Text fontSize='16px' fontWeight={700}>
            {amount}
          </Text>
        </HStack>
      </Flex>
    </>
  );
}

function View({ plan, complete = false, border }) {
  const paymentMode = useSelector(selectPaymentMode);
  const fmtter = currencyFormatter(plan?.lease?.currency);
  const endDate = formatDateToHumanDate(plan?.lease?.endDate || '');
  const oneTimeAmount = fmtter.format(plan?.payment?.oneTimePayment || 0);
  const monthlyAmount = fmtter.format(plan?.payment?.monthlyPayment || 0);
  const isMonthly =
    plan?.payment.choice === 'monthly' ||
    paymentMode === planPaymentMode.MONTHLY;
  return isMonthly ? (
    <MonthlySummary
      amount={monthlyAmount}
      endDate={endDate}
      complete={complete}
      border={border}
    />
  ) : (
    <OneTimeSummary
      amount={oneTimeAmount}
      complete={complete}
      border={border}
    />
  );
}

export default View;
