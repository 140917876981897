import { AddressAutofill } from '@mapbox/search-js-react';
import WrapInputLabel from 'components/shared/wrap-input-label';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ApplyClaimsButtons from 'layouts/appy-claims-buttons';
import { useUploadDocumentMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { selectClaimsDraftValue } from 'redux/features/claims/claimsSlice';
import { isEmpty } from 'common/utils/isEmpty';
import FileUploadInput from 'components/shared/file-upload-input';
import FormError from 'components/shared/form-error';
import Input from 'components/shared/input';

const StartClaimHomePurchase = ({
  onBack,
  onNext,
  values,
  isValid,
  handleChange,
  setFieldValue,
  handleBlur,
  saveDraft,
  setFieldTouched,
}) => {
  // STATES
  const [disableFileUpload, setDisableFileUpload] = useState(false);

  // HOOKS
  const draftedClaimsValue = useSelector(selectClaimsDraftValue);
  const [
    uploadSinglePDF,
    {
      isLoading: isUploadLoading,
      isSuccess: isUploadSuccess,
      isError: isUploadError,
    },
  ] = useUploadDocumentMutation();

  return (
    <div className='w-full flex flex-col justify-between gap-8 h-full'>
      {/* inputs */}
      <form
        onSubmit={(e) => e.preventDefault()}
        className='flex flex-col gap-6 w-full'
      >
        {/* Street Address 1 */}
        <div className='flex flex-col gap-2'>
          <WrapInputLabel isRequired={true}>
            <label className='text-[#191923] text-lg font-medium'>
              Street Address 1
            </label>
          </WrapInputLabel>
          <AddressAutofill
            accessToken={`${process.env.REACT_APP_MAPBOX_TOKEN}`}
          >
            <Input
              autoComplete='street-address'
              placeholderText='Start typing to search for an address'
              value={values.purchasedHome.homeAddress.streetAddress1}
              onChange={({ target: { value } }) => {
                setFieldValue(
                  'purchasedHome.homeAddress.streetAddress1',
                  value
                );
              }}
              onBlur={handleBlur}
              name='purchasedHome.homeAddress.streetAddress1'
            />
          </AddressAutofill>
          <FormError name='purchasedHome.homeAddress.streetAddress1' />
        </div>

        {/* Street Address 2 */}
        <div className='flex flex-col gap-2'>
          <WrapInputLabel isRequired={false}>
            <label className='text-[#191923] text-lg font-medium'>
              Street Address 2
            </label>
          </WrapInputLabel>
          <Input
            // autoComplete='address-level3'
            placeholderText='Specific address (e.g. Apt, Suite, Bldg) (if applicable)'
            value={values.purchasedHome.homeAddress.streetAddress2}
            onChange={({ target: { value } }) => {
              setFieldValue('purchasedHome.homeAddress.streetAddress2', value);
            }}
            onBlur={handleBlur}
            name='purchasedHome.homeAddress.streetAddress2'
          />
          <FormError name='purchasedHome.homeAddress.streetAddress2' />
        </div>

        {/* City */}
        <div className='flex flex-col gap-2'>
          <WrapInputLabel isRequired={true}>
            <label className='text-[#191923] text-lg font-medium'>City</label>
          </WrapInputLabel>
          <Input
            autoComplete='address-level2'
            placeholderText='Your City'
            value={values.purchasedHome.homeAddress.city}
            onChange={({ target: { value } }) => {
              setFieldValue('purchasedHome.homeAddress.city', value);
            }}
            onBlur={handleBlur}
            name='purchasedHome.homeAddress.city'
          />
          <FormError name='purchasedHome.homeAddress.city' />
        </div>

        {/* State */}
        <div className='flex flex-col gap-2'>
          <WrapInputLabel isRequired={true}>
            <label className='text-[#191923] text-lg font-medium'>State</label>
          </WrapInputLabel>
          <Input
            autoComplete='address-level1'
            placeholderText='Your State'
            value={values.purchasedHome.homeAddress.state}
            onChange={({ target: { value } }) => {
              setFieldValue('purchasedHome.homeAddress.state', value);
            }}
            onBlur={handleBlur}
            name='purchasedHome.homeAddress.state'
          />
          <FormError name='purchasedHome.homeAddress.state' />
        </div>

        {/* Country */}
        <div className='flex flex-col gap-2'>
          <WrapInputLabel isRequired={true}>
            <label className='text-[#191923] text-lg font-medium'>
              Country
            </label>
          </WrapInputLabel>
          <Input
            autoComplete='country-name'
            placeholderText='Your Country'
            value={values.purchasedHome.homeAddress.country}
            onChange={({ target: { value } }) => {
              setFieldValue('purchasedHome.homeAddress.country', value);
            }}
            onBlur={handleBlur}
            name='purchasedHome.homeAddress.country'
          />
          <FormError name='purchasedHome.homeAddress.country' />
        </div>

        {/* Postal Code */}
        <div className='flex flex-col gap-2'>
          <WrapInputLabel isRequired={true}>
            <label className='text-[#191923] text-lg font-medium'>
              Zip Code
            </label>
          </WrapInputLabel>
          <Input
            autoComplete='postal-code'
            placeholderText='Your Zip Code'
            value={values.purchasedHome.homeAddress.postalCode}
            onChange={({ target: { value } }) => {
              setFieldValue('purchasedHome.homeAddress.postalCode', value);
            }}
            onBlur={handleBlur}
            name='purchasedHome.homeAddress.postalCode'
          />
          <FormError name='purchasedHome.homeAddress.postalCode' />
        </div>

        {/* date */}
        <div className='flex gap-3 items-start w-full pt-2 mobile:flex-col mobile:gap-6'>
          {/* start date */}
          <div className='flex flex-col gap-2 w-full'>
            <WrapInputLabel isRequired={true}>
              <label className='text-[#191923] text-lg font-medium'>
                Offer Date
              </label>
            </WrapInputLabel>

            <Input
              placeholderText='Select date'
              type='date'
              value={values.purchasedHome.offerDate}
              onChange={(e) => {
                setFieldTouched('purchasedHome.offerDate');
                handleChange(e);
              }}
              onBlur={handleBlur}
              name='purchasedHome.offerDate'
            />
            <FormError name='purchasedHome.offerDate' />
          </div>

          {/* end date */}
          <div className='flex flex-col gap-2 w-full'>
            <WrapInputLabel isRequired={true}>
              <label className='text-[#191923] text-lg font-medium'>
                Expected Possession Date
              </label>
            </WrapInputLabel>

            <Input
              placeholderText='Select date'
              type='date'
              value={values.purchasedHome.proposedStart}
              onChange={(e) => {
                setFieldTouched('purchasedHome.proposedStart');
                handleChange(e);
              }}
              onBlur={handleBlur}
              name='purchasedHome.proposedStart'
            />
            <FormError name='purchasedHome.proposedStart' />
          </div>
        </div>

        {/* Upload a copy of your offer of purchase */}
        <div className='flex flex-col gap-2'>
          <WrapInputLabel isRequired={true}>
            <label className='text-[#191923] text-lg font-medium'>
              Dated Offer of Purchase
            </label>
          </WrapInputLabel>
          <FileUploadInput
            disableUpload={disableFileUpload}
            isUploadError={isUploadError}
            isUploadLoading={isUploadLoading}
            isUploadSuccess={
              isUploadSuccess ||
              !isEmpty(draftedClaimsValue?.purchasedHome?.offerPdfUrl)
            }
            handlePDFClear={() => {
              setFieldValue('purchasedHome.offerPdfUrl', '');
            }}
            handlePDFUpload={(file) => {
              uploadSinglePDF({
                docFile: file,
              })
                .unwrap()
                .then((data) => {
                  setDisableFileUpload(true);
                  setFieldValue('purchasedHome.offerPdfUrl', data?.data);
                  toast.info(data?.status || 'Success');
                })
                .catch((error) => {
                  toast.error(error?.msg || 'failed to upload document');
                });
            }}
          />
          <FormError name='purchasedHome.offerPdfUrl' />
        </div>
      </form>

      {/* button */}
      <ApplyClaimsButtons
        moveOn={true}
        disabledNextBtn={!isValid}
        onNextClick={() => {
          onNext();
          saveDraft(values);
        }}
        onBackClick={() => {
          onBack();
          saveDraft(values);
        }}
      />
    </div>
  );
};

export default StartClaimHomePurchase;
