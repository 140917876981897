import { useState } from 'react';
import WrapInputLabel from 'components/shared/wrap-input-label';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ApplyClaimsButtons from 'layouts/appy-claims-buttons';
import { useUploadDocumentMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { selectClaimsDraftValue } from 'redux/features/claims/claimsSlice';
import { isEmpty } from 'common/utils/isEmpty';
import FileUploadInput from 'components/shared/file-upload-input';
import FormError from 'components/shared/form-error';
import Input from 'components/shared/input';
import { PhoneNumberInput } from 'common/components/phone-input';

const StartClaimNewJob = ({
  onBack,
  onNext,
  isValid,
  values,
  handleChange,
  setFieldValue,
  handleBlur,
  saveDraft,
  setFieldTouched,
}) => {
  // STATES
  const [disableFileUpload, setDisableFileUpload] = useState(false);

  // HOOKS
  const draftedClaimsValue = useSelector(selectClaimsDraftValue);
  const [
    uploadSinglePDF,
    {
      isLoading: isUploadLoading,
      isSuccess: isUploadSuccess,
      isError: isUploadError,
    },
  ] = useUploadDocumentMutation();

  return (
    <div className='w-full flex flex-col justify-between gap-7 h-full'>
      {/* inputs */}
      <div className='flex flex-col gap-6 w-full'>
        {/* header text */}
        {/* <p className='text-lg font-medium text-[#191923]'>
          Please provide the details of the new job or job transfer you are accepting.
        </p> */}

        {/* company */}
        <div className='flex flex-col gap-2'>
          <WrapInputLabel isRequired={true}>
            <label className='text-[#191923] text-lg font-medium'>
              Company Name
            </label>
          </WrapInputLabel>
          <Input
            placeholderText='Enter the name of the company (e.g. ACME Inc.)'
            name='newJob.companyName'
            value={values.newJob.companyName}
            onChange={(e) => {
              setFieldTouched('newJob.companyName');
              handleChange(e);
            }}
            onBlur={handleBlur}
          />
          <FormError name='newJob.companyName' />
        </div>

        {/* job title */}
        <div className='flex flex-col gap-2'>
          <WrapInputLabel isRequired={true}>
            <label className='text-[#191923] text-lg font-medium'>
              Job Title
            </label>
          </WrapInputLabel>
          <Input
            placeholderText='Enter your job title (e.g. Software Engineer)'
            name='newJob.jobTitle'
            value={values.newJob.jobTitle}
            onChange={(e) => {
              setFieldTouched('newJob.jobTitle');
              handleChange(e);
            }}
            onBlur={handleBlur}
          />
          <FormError name='newJob.jobTitle' />
        </div>

        {/* date */}
        <div className='flex gap-3 items-start w-full pt-2 mobile:flex-col mobile:gap-6'>
          {/* start date */}
          <div className='flex flex-col gap-2 w-full'>
            <WrapInputLabel isRequired={true}>
              <label className='text-[#191923] text-lg font-medium'>
                Offer Date
              </label>
            </WrapInputLabel>

            <Input
              placeholderText='Select the date of the offer'
              type='date'
              name='newJob.offerDate'
              value={values.newJob.offerDate}
              onChange={(e) => {
                setFieldTouched('newJob.offerDate');
                handleChange(e);
              }}
              onBlur={handleBlur}
            />
            <FormError name='newJob.offerDate' />
          </div>

          {/* end date */}
          <div className='flex flex-col gap-2 w-full'>
            <WrapInputLabel isRequired={true}>
              <label className='text-[#191923] text-lg font-medium'>
                Proposed Start Date
              </label>
            </WrapInputLabel>

            <Input
              placeholderText='Select the proposed start date'
              type='date'
              name='newJob.proposedStart'
              value={values.newJob.proposedStart}
              onChange={(e) => {
                setFieldTouched('newJob.proposedStart');
                handleChange(e);
              }}
              onBlur={handleBlur}
            />
            <FormError name='newJob.proposedStart' />
          </div>
        </div>

        {/* contact name */}
        <div className='flex flex-col gap-2'>
          <WrapInputLabel isRequired={true}>
            <label className='text-[#191923] text-lg font-medium'>
              Contact Name
            </label>
          </WrapInputLabel>
          <Input
            placeholderText='Enter the name of the hiring manager or your direct report'
            name='newJob.contactName'
            value={values.newJob.contactName}
            onChange={(e) => {
              setFieldTouched('newJob.contactName');
              handleChange(e);
            }}
            onBlur={handleBlur}
          />
          <FormError name='newJob.contactName' />
        </div>

        {/* Email */}
        <div className='flex flex-col gap-2'>
          <WrapInputLabel isRequired={true}>
            <label className='text-[#191923] text-lg font-medium'>
              Contact email
            </label>
          </WrapInputLabel>
          <Input
            placeholderText='Enter the email of the hiring manager or your direct report'
            name='newJob.contactEmail'
            value={values.newJob.contactEmail}
            onChange={(e) => {
              setFieldTouched('newJob.contactEmail');
              handleChange(e);
            }}
            onBlur={handleBlur}
          />
          <FormError name='newJob.contactEmail' />
        </div>

        {/* contact phone */}
        <div className='flex flex-col gap-2'>
          <WrapInputLabel isRequired={true}>
            <label className='text-[#191923] text-lg font-medium'>
              Contact phone
            </label>
          </WrapInputLabel>
          <PhoneNumberInput 
            name='newJob.contactPhone'
            value={values.newJob.contactPhone}
            onChange={(e) => {
              setFieldValue('newJob.contactPhone', e);
            }}
            onBlur={handleBlur}            
          />

          <FormError name='newJob.contactPhone' />
        </div>

        {/* Upload a copy of your offer of employment */}
        <div className='flex flex-col gap-2'>
          <WrapInputLabel isRequired={true}>
            <label className='text-[#191923] text-lg font-medium'>
              Dated Offer of Employment
            </label>
          </WrapInputLabel>
          <FileUploadInput
            disableUpload={disableFileUpload}
            isUploadError={isUploadError}
            isUploadLoading={isUploadLoading}
            isUploadSuccess={
              isUploadSuccess ||
              !isEmpty(draftedClaimsValue?.newJob?.offerPdfUrl)
            }
            handlePDFClear={() => {
              setFieldValue('newJob.offerPdfUrl', '');
            }}
            handlePDFUpload={(file) => {
              uploadSinglePDF({
                docFile: file,
              })
                .unwrap()
                .then((data) => {
                  setDisableFileUpload(true);
                  setFieldValue('newJob.offerPdfUrl', data?.data);
                  toast.info(data?.status || 'Success');
                })
                .catch((error) => {
                  toast.error(error?.msg || 'failed to upload document');
                });
            }}
          />
          <FormError name='newJob.offerPdfUrl' />
        </div>
      </div>

      {/* button */}
      <ApplyClaimsButtons
        moveOn={true}
        disabledNextBtn={!isValid}
        onNextClick={() => {
          onNext();
          saveDraft(values);
        }}
        onBackClick={() => {
          onBack();
          saveDraft(values);
        }}
      />
    </div>
  );
};

export default StartClaimNewJob;
