/* eslint-disable no-unused-vars */
import WrapInputLabel from 'components/shared/wrap-input-label';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ApplyClaimsButtons from 'layouts/appy-claims-buttons';
import { useUploadDocumentMutation } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { selectClaimsDraftValue } from 'redux/features/claims/claimsSlice';
import { isEmpty } from 'common/utils/isEmpty';
import FileUploadInput from 'components/shared/file-upload-input';
import FormError from 'components/shared/form-error';
import TextAreaInput from 'components/shared/text-area-input';

const StartClaimOther = ({
  onBack,
  onNext,
  values,
  isValid,
  handleChange,
  setFieldValue,
  handleBlur,
  saveDraft,
  setFieldTouched,
}) => {
  // STATES
  const [disableFileUpload, setDisableFileUpload] = useState(false);

  // HOOKS
  const draftedClaimsValue = useSelector(selectClaimsDraftValue);
  const [
    uploadSinglePDF,
    {
      isLoading: isUploadLoading,
      isSuccess: isUploadSuccess,
      isError: isUploadError,
    },
  ] = useUploadDocumentMutation();

  return (
    <div className='w-full h-full flex flex-col justify-between gap-8'>
      {/* inputs */}
      <div className='flex flex-col gap-6 w-full'>
        {/* Description: */}
        <div className='flex flex-col gap-2 w-full'>
          <WrapInputLabel isRequired={true}>
            <label className='text-[#191923] text-lg font-medium'>
              Cancellation Reason
            </label>
          </WrapInputLabel>

          <TextAreaInput
            placeholderText='Describe why you are cancelling your lease'
            value={values?.otherClaim?.description}
            onChange={(e) => {
              setFieldTouched('otherClaim.description');
              handleChange(e);
            }}
            onBlur={handleBlur}
            name='otherClaim.description'
          />
          <FormError name='otherClaim.description' />
        </div>

        {/* Upload any supporting documents: */}
        <div className='flex flex-col gap-2'>
          <WrapInputLabel isRequired={true}>
            <label className='text-[#191923] text-lg font-medium'>
              Supporting Documents
            </label>
          </WrapInputLabel>
          <FileUploadInput
            disableUpload={disableFileUpload}
            isUploadError={isUploadError}
            isUploadLoading={isUploadLoading}
            isUploadSuccess={
              isUploadSuccess ||
              !isEmpty(draftedClaimsValue?.otherClaim?.supportingDocUrl)
            }
            handlePDFClear={() => {
              setFieldValue('otherClaim.supportingDocUrl', '');
            }}
            handlePDFUpload={(file) => {
              uploadSinglePDF({
                docFile: file,
              })
                .unwrap()
                .then((data) => {
                  setDisableFileUpload(true);
                  setFieldValue('otherClaim.supportingDocUrl', data?.data);
                  toast.info(data?.status || 'Success');
                })
                .catch((error) => {
                  toast.error(error?.msg || 'failed to upload document');
                });
            }}
          />
          <FormError name='otherClaim.supportingDocUrl' />
        </div>
      </div>

      {/* button */}
      <ApplyClaimsButtons
        moveOn={true}
        disabledNextBtn={!isValid}
        onNextClick={() => {
          onNext();
          saveDraft(values);
        }}
        onBackClick={() => {
          onBack();
          saveDraft(values);
        }}
      />
    </div>
  );
};

export default StartClaimOther;
