import { RiArrowDownSFill } from 'react-icons/ri';
import { Link, useParams } from 'react-router-dom';
import ClaimsDetailAction from 'components/claims/claims-detail-action';
import ClaimsDetailAlert from 'components/claims/claims-detail-alert';
import ClaimsDetailInfo from 'components/claims/claims-detail-info';
import DocumentPreview from 'common/components/document-preview';
import EmptyData from 'components/shared/empty-data';
import LoadingSpinner from 'components/shared/loading-spinner';
import StatusLabel from 'components/shared/status-label';
import DashboardMainLayout from 'layouts/dashboard-main';
import { useGetSingleClaimQuery, useRetrieveUserDetailsQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { claimTypeEnum, statusEnum } from 'utils/enums';
import { formatDateToHumanTime } from 'common/utils/formDateToHumanTime';
import { isEmpty } from 'common/utils/isEmpty';
import ContactCard from 'components/shared/contact-card';
import { Flex, Text } from '@chakra-ui/react';
import { InfoCard, InfoField, InfoGrid } from 'common/components/info-card';
import PlanInfoCard from 'components/plans/plan-info-card';
import Button from 'common/components/button';
import DownloadPdfButton from 'components/plans/download-button';
import { useNavigate } from 'react-router-dom';

const ClaimDetails = () => {
  // HOOKS
  const { claimId } = useParams();
  const { data: singleClaim, isLoading: getSingleClaimLoading } =
    useGetSingleClaimQuery(claimId);
  const { data: userDetails, isLoading: getUserDetailsLoading } =
  useRetrieveUserDetailsQuery();

  // DATA INITIALIZATION
  const navigate = useNavigate();
  const plan = singleClaim?.planId;

  console.log('Plan', plan)

  // Get document url based on the claim type
  const documentUrl =
    singleClaim?.newJob?.offerPdfUrl ||
    singleClaim?.purchasedHome?.offerPdfUrl ||
    singleClaim?.otherClaim?.supportingDocUrl;

  // Get the document name from the url
  const regex = /https:\/\/cios-api-images\.s3\.amazonaws\.com\/listings\/(.+)/;
  const match = documentUrl?.match(regex);
  const documentName = match ? match[1] : '';

  return (
    <DashboardMainLayout title='Claim Details'>
      <div className='flex flex-col w-full gap-6'>
        {/* breadcrumb */}
        <div className='flex gap-3 items-center'>
          <Link
            to='/dashboard/home'
            className='font-normal text-base text-[#CED0CE]'
          >
            Home
          </Link>
          <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
          <Link
            to='/dashboard/claims'
            className='text-[#CED0CE] text-base font-normal'
          >
            Claims{' '}
          </Link>
          <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
          <Link
            to=''
            className='text-[#191923] text-base font-normal uppercase'
          >
            {singleClaim?.id}
          </Link>
        </div>

        {/* mobile: back button */}
        {/* <Link
          to='/dashboard/claims'
          className='text-[#191923] text-sm font-medium hidden mobile:inline-flex items-center gap-2'
        >
          <IoIosArrowBack className='text-xl text-[#9CA3AF]' />
          Back
        </Link> */}

        {/* body content */}
        {getSingleClaimLoading ? (
          <LoadingSpinner />
        ) : isEmpty(singleClaim) ? (
          <EmptyData />
        ) : (
          <>
            {/* Claims feedback */}
            {singleClaim?.status === statusEnum.Denied ? (
              <ClaimsDetailAlert
                claim={singleClaim}
                alertFeedback={singleClaim?.deniedFeedback || ''}
              />
            ) : singleClaim?.status === statusEnum.Approved ? (
              <ClaimsDetailAction
                claim={singleClaim}
              />
            ) : singleClaim?.status === statusEnum.Confirmed ? (
              <ClaimsDetailInfo
                claim={singleClaim}
              />
            ) : null}

            {/* Submitted Information */}
            <Flex className='w-full flex-col gap-6 mt-6'>
              {/* header */}
              <Flex className='flex-col gap-4 w-full'>
                <Flex className='gap-4 items-center justify-between'>
                  <h2 className='text-2xl font-bold'>
                    Your Submitted Claim Details
                  </h2>
                  <StatusLabel status={singleClaim?.status} />
                </Flex>
                {[
                  statusEnum.Draft,
                  statusEnum.submitted,
                  statusEnum.inReview,
                ].includes(singleClaim?.status) && (
                  <Text>
                    We are currently reviewing your claim. You will receive an
                    email when we have an update for you.
                  </Text>
                )}
              </Flex>

              {/* submitted information in card */}
              <InfoCard title='Submitted Information'>
                {/* shared information for all claim types */}
                <InfoGrid>
                  <InfoField title='Claim Number'>{singleClaim?.id}</InfoField>
                  <InfoField title='Submitted On'>
                    {formatDateToHumanTime(singleClaim?.submissionAt)}
                  </InfoField>
                  <InfoField title='Cancellation Date'>
                    {formatDateToHumanTime(singleClaim?.moveOutDate)}
                  </InfoField>
                  <InfoField title='Claim Type'>
                    {singleClaim?.claimType === claimTypeEnum.NEW_JOB
                      ? 'New Job'
                      : singleClaim?.claimType === claimTypeEnum.PURCHASED_HOME
                      ? 'Purchased Home'
                      : 'Other Claim'}
                  </InfoField>
                </InfoGrid>

                {/* information for new jobs */}
                {singleClaim?.claimType === claimTypeEnum.NEW_JOB && (
                  <InfoGrid>
                    <InfoField title='Job Title'>
                      {singleClaim?.newJob?.jobTitle}
                    </InfoField>
                    <InfoField title='Contact Name'>
                      {singleClaim?.newJob?.contactName}
                    </InfoField>
                    <InfoField title='Contact Phone'>
                      {singleClaim?.newJob?.contactPhone}
                    </InfoField>
                    <InfoField title='Contact Email'>
                      {singleClaim?.newJob?.contactEmail}
                    </InfoField>
                    <InfoField title='Offer Date'>
                      {formatDateToHumanTime(singleClaim?.newJob?.offerDate)}
                    </InfoField>
                    <InfoField title='Proposed Start Date'>
                      {formatDateToHumanTime(
                        singleClaim?.newJob?.proposedStart
                      )}
                    </InfoField>
                  </InfoGrid>
                )}

                {/* information for purchased homes */}
                {singleClaim?.claimType === claimTypeEnum.PURCHASED_HOME && (
                  <InfoGrid>
                    <InfoField title='Street Address 1' className='col-span-2'>
                      {singleClaim?.purchasedHome?.homeAddress?.streetAddress1}
                    </InfoField>
                    <InfoField title='Street Address 2' className='col-span-2'>
                      {singleClaim?.purchasedHome?.homeAddress?.streetAddress2}
                    </InfoField>
                    <InfoField title='City'>
                      {singleClaim?.purchasedHome?.homeAddress?.city}
                    </InfoField>
                    <InfoField title='State'>
                      {singleClaim?.purchasedHome?.homeAddress?.state}
                    </InfoField>
                    <InfoField title='Zip Code'>
                      {singleClaim?.purchasedHome?.homeAddress?.postalCode}
                    </InfoField>
                    <InfoField title='Country'>
                      {singleClaim?.purchasedHome?.homeAddress?.country}
                    </InfoField>
                    <InfoField title='Offer Date'>
                      {formatDateToHumanTime(
                        singleClaim?.purchasedHome?.offerDate
                      )}
                    </InfoField>
                    <InfoField title='Expected Possession Date'>
                      {formatDateToHumanTime(
                        singleClaim?.purchasedHome?.proposedStart
                      )}
                    </InfoField>
                  </InfoGrid>
                )}

                {/* information for other claims */}
                {singleClaim?.claimType === claimTypeEnum.OTHER_CLAIM && (
                  <InfoGrid>
                    <InfoField title='Claim Description' className='col-span-4'>
                      {singleClaim?.otherClaim?.description}
                    </InfoField>
                  </InfoGrid>
                )}

                {/* Submitted Documents */}
                <InfoGrid>
                  <InfoField title='Submitted Documents' className='col-span-4'>
                    <DocumentPreview url={documentUrl} label={documentName} />
                  </InfoField>
                </InfoGrid>
              </InfoCard>
            </Flex>

            {/* Plan Card */}
            <Flex className='w-full flex-col gap-6 my-6'>
              {/* header */}
              <Flex className='flex-col gap-4 w-full'>
                <Flex className='gap-4 items-center justify-between'>
                  <h2 className='text-2xl font-bold'>Your Plan Details</h2>
                  <StatusLabel status={plan?.status} />
                </Flex>
              </Flex>

              {/* plan info card */}
              <Flex className='flex-col gap-4 w-full'>
                <PlanInfoCard
                  streetAddress1={plan.lease.address.streetAddress1}
                  streetAddress2={plan.lease.address.streetAddress2}
                  city={plan.lease.address.city}
                  state={plan.lease.address.state}
                  country={plan.lease.address.country}
                  postalCode={plan.lease.address.postalCode}
                  name={
                    userDetails?.name ||
                    `${userDetails?.firstName} ${userDetails?.lastName}`
                  }
                  startDate={plan.lease.startDate}
                  endDate={plan.lease.endDate}
                  planId={plan.id}
                />
                <Flex className='gap-4 mobile:gap-2 mobile:flex-col'>
                  <Button
                    className='cursor-pointer hover:opacity-80 transition-all'
                    onClick={() => navigate(`/dashboard/plans/${plan.id}`)}
                    title='View Plan Details'
                  />
                  {plan.id && <DownloadPdfButton planId={plan.id} />}
                </Flex>
              </Flex>
            </Flex>
          </>
        )}
      </div>

      {/* Contact Section */}
      <ContactCard title='Have Questions About Your Claim?'>
      {[
                  statusEnum.Draft,
                  statusEnum.submitted,
                  statusEnum.inReview,
                ].includes(singleClaim?.status)? (
                  <Text>
                    We are now reviewing your claims based on our terms and notify you of the decision through the app. If you have any questions during the process, feel free to reach out to our customer service team anytime with the information below.
                  </Text>
                ) : (
                  <Text>
                    If you have any questions about your claim, please reach out to our customer service team anytime with the information below.
                  </Text>
                )}
      </ContactCard>
    </DashboardMainLayout>
  );
};

export default ClaimDetails;
