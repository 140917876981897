/* eslint-disable no-unused-vars */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Card,
  Divider,
  Flex,
  HStack,
  Icon,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import Button from 'common/components/button';
import LoadingSpinner from 'components/shared/loading-spinner';
import FinishSetupIntent from 'components/stripe/finish-setup-intent';
import Payment from 'components/stripe/payment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useGetSinglePlanQuery,
  useRequestSetupIntentMutation,
  useRetrieveUserDetailsQuery,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import {
  selectPlansValues,
  selectPaymentMode,
} from 'redux/features/plans/plansSlice';
import { currency, planPaymentMode } from 'utils/enums';
import { isEmpty } from 'common/utils/isEmpty';
import { currencyFormatter } from 'utils/currency';
import PaymentSummary from 'components/plans/payment-summary';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import { SetupCard } from 'components/stripe/setup-card';
import PlanInfoCard from 'components/plans/plan-info-card';
import { FaCircleXmark, FaCopy, FaEnvelope, FaPhone } from 'react-icons/fa6';
import { FaCheckCircle } from 'react-icons/fa';
import AnchorLinkTag from 'components/shared/anchor-link-tag';
import ContactCard from 'components/shared/contact-card';
import DisclosureCard from '../disclosure-card';
import CoverageCard from '../coverage-card';
import PaymentSummaryCard from '../payment-summary-card';

const PlanStepThree = ({ onBackClick, onNextClick, isGettingPlan }) => {
  const { trackPageLoad, trackCustomEvent } = useUserTracker();

  useEffect(() => {
    trackPageLoad('planStepThree');
  }, []);

  // STATES
  const [clientSecret, setSecret] = useState();
  const [isPaymentSuccess, setPaymentSuccess] = useState(false);

  // HOOKS
  const plansValue = useSelector(selectPlansValues);
  const { data: singlePlan, isLoading: isGettingSinglePlan } =
    useGetSinglePlanQuery(plansValue?.id, {
      skip: isEmpty(plansValue?.id),
    });

  const [requestSetupIntent] = useRequestSetupIntentMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const setupIntent = searchParams.get('setup_intent');
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();

  const address = singlePlan?.lease?.address || {};
  const paymentMode = useSelector(selectPaymentMode);

  // SIDE EFFECTS
  useEffect(() => {
    requestSetupIntent()
      .unwrap()
      .then((response) => {
        setSecret(response?.data?.clientSecret);
      })
      .catch((error) => {
        toast.error(error?.message || 'Failed to setup intent!');
      });
  }, []);

  return (
    <Flex w='100%' flexDir='column' gap={10}>
      {/* container */}

      {/* plan card and info */}
      <Flex className='flex-col gap-6'>
        {/* plan heading and status */}
        <Flex className='flex-col gap-4 w-full pt-4'>
          <Flex className='gap-4 items-center justify-between'>
            <h2 className='text-2xl font-bold'>Your Plan Details</h2>
          </Flex>
        </Flex>

        {/* plan info card */}
        <Flex className='flex-col gap-4 w-full'>
          <PlanInfoCard
            streetAddress1={address?.streetAddress1}
            streetAddress2={address?.streetAddress2}
            city={address?.city}
            state={address?.state}
            country={address?.country}
            postalCode={address?.postalCode}
            name={
              userDetails?.name ||
              `${userDetails?.firstName} ${userDetails?.lastName}`
            }
            startDate={singlePlan?.lease?.startDate}
            endDate={singlePlan?.lease?.endDate}
            planId={singlePlan?.id}
            inactive
          />
        </Flex>
      </Flex>

      {/* plan coverages section */}
      <Flex className='flex-col gap-6 mt-6'>
        {/* coverages heading */}
        <Flex className='flex-col gap-2 w-full items-left'>
          <h2 className='text-2xl font-bold'>Your Plan Coverages</h2>
          <Text>
            Your plan includes the following coverages, limits, deductibles, and
            exclusions.
          </Text>
        </Flex>

        <DisclosureCard />

        {/* Limits */}
        <CoverageCard>
          The coverage limit is the maximum amount that can be paid for a
          covered cancellation. For example, if you have a limit of 6 months,
          the maximum amount that will be covered for a cancellation is 6 months
          of rent.
        </CoverageCard>

        {/* Deductibles */}
        <CoverageCard
          title='Deductible'
          description='The deductible for this plan is the lesser of:'
          limit1='$500'
          limit2='one months rent'
        >
          A deductible is the amount you pay out-of-pocket for covered
          cancellations before your coverage starts to pay. For example, if you
          have a $500 deductible, you would pay the first $500 of covered
          expenses for a cancellation.
        </CoverageCard>

        {/* Elimination Period */}
        <CoverageCard
          title='Elimination Period'
          description='The elimination period is the greater of:'
          limit1='one month'
          limit2='1/12 of the total lease term'
        >
          The elimination period is the number of days you must wait after a
          cancellation is submitted before benefits are paid out. For example,
          if you have a one month elimination period, you would wait one month
          after a cancellation is filed before any benefits are paid out.
        </CoverageCard>
      </Flex>      

      <Flex className='flex-col w-full gap-8'>        
        {/* payment link */}
        {/* back button only shown when payment intent isn't set up */}
        <div className='flex-col w-full gap-8 flex justify-center pt-6'>
          {/* Heading */}
          <Flex className='flex-col gap-2 w-full pt-4'>
            <h2 className='text-2xl font-bold'>Complete Payment</h2>
            <Text>
              Please complete payment by credit card or bank account in order to
              complete your application. Once completed, your application will
              be reviewed. Once approved, you will be able to activate your
              plan. If your application is not approved, you will receive a
              refund for any payments made.
            </Text>
            <Text>
              Your coverage may be voided if you misrepresent or conceal any
              material information.{' '}
              <AnchorLinkTag
                className='underline'
                hrefPath='https://www.releaserent.com/legal/lease-flexibility-coverage-terms-conditions'
              >
                Read the full terms and conditions of your plan.
              </AnchorLinkTag>
            </Text>
          </Flex>

          <PaymentSummaryCard plan={singlePlan} complete={false} paymentMode={paymentMode}/>
          
          <Flex>
          {isEmpty(setupIntent) && (
            <Button
            theme='secondary'
            onClick={onBackClick}
            title='Change Payment Plan'
          />
          )}
          </Flex>
          

          {/* Payment component */}
          {clientSecret &&
            (!isEmpty(setupIntent) ? (
              <FinishSetupIntent
                removeSetupIntent={() => {
                  searchParams.delete('setup_intent');
                  setSearchParams(searchParams, { replace: true });
                }}
                onNextClick={onNextClick}
                setPaymentSuccess={setPaymentSuccess}
                setupIntentID={setupIntent}
              />
            ) : (
              <Payment
                clientSecret={clientSecret}
                returnUrl={`${window.location.href}`}
              />
            ))}
        </div>
      </Flex>

      <ContactCard />
    </Flex>
  );
};

export default PlanStepThree;
