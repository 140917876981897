import React from 'react';
import {
  Card,
  Accordion,
  AccordionItem,
  Flex,
  Text,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react';

const CoverageCard = ({
  title = 'Coverage Limit',
  description = 'Maximum lifetime coverage for this plan is limited to the lesser of:',
  limit1 = '6 months rent',
  limit2 = '$30,000',
  children,
}) => {
  return (
    <Card className='rounded-lg border border-slate-200'>
      <Accordion
        allowMultiple
        className='flex w-full flex-col rounded-md overflow-hidden'
      >
        <AccordionItem className='border-none w-full'>
          <Flex className='flex flex-row items-center mobile:items-start mobile:flex-col justify-between px-6 py-5 gap-3 mobile:gap-6 w-full'>
            <Flex className='flex-col'>
              <h2 className='text-2xl font-bold'>{title}</h2>
              {description}
            </Flex>
            {limit2 ? (
              <Flex className='flex-col mobile:flex-row items-end mobile:gap-2'>
                <Text className='text-2xl font-medium'>{limit1}</Text>
                <Text className='pb-1 mobile:pb-0.5'>or {limit2}</Text>
              </Flex>
            ) : (
              <Flex className='flex-col'>
                <Text className='text-2xl font-medium'>{limit1}</Text>
              </Flex>
            )}
          </Flex>

          {children && (
            <>
              <AccordionButton className='px-6 py-3 hover:bg-slate-100 border-t border-slate-200'>
                <div className='w-full text-left text-sm font-semibold py-1 uppercase'>
                  What does this mean?
                </div>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel className='border-t p-6 flex flex-col gap-8'>
                {children}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </Card>
  );
};

export default CoverageCard;
