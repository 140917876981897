import React from 'react';
import ClaimsInfoLayout from 'layouts/claims-info';
import { InfoField, InfoGrid } from 'common/components/info-card';
import { Flex, Text } from '@chakra-ui/react';
import {
  formatDateToHumanDate,
  formatDateToHumanTime,
} from 'common/utils/formDateToHumanTime';
import ContactCard from 'components/shared/contact-card';

const ClaimsDetailAlert = ({
  title = 'Claim Not Approved',
  alertFeedback = '',
  claim,
}) => {
  // Data Initialization
  const address = claim?.planId?.lease?.address;
  const name =
    claim?.userId?.name ||
    `${claim?.userId?.firstName} ${claim?.userId?.lastName}`;

  return (
    <ClaimsInfoLayout title={title}>
      <Flex className='flex mobile:flex-col w-full gap-8 mobile:gap-4'>
        <InfoField title='Plan Number'>{claim?.planId.id}</InfoField>
        <InfoField title='Claim Number'>{claim?.id}</InfoField>
        <InfoField title='Submitted On'>
          {formatDateToHumanTime(claim?.submissionAt)}
        </InfoField>
      </Flex>

      {/* Standard Claims Denial Text */}
      <InfoField title='Detailed Feedback'>
        <Flex className='flex-col w-full gap-3 p-6 border border-slate-200 rounded-md mt-2'>
          {/* <Text>
            Dear {name},
          </Text> */}
          <Text>
            Thank you for submitting a claim for your apartment at{' '}
            {address?.streetAddress1}, {address?.streetAddress2},{' '}
            {address?.city}, {address?.state}, {address?.postalCode}.
          </Text>
          <Text>
            We have reviewed your recent claim regarding your proposed move-out
            date on {formatDateToHumanDate(claim?.moveOutDate)}.
          </Text>
          <Text>
            Our investigation based on the information and documents you
            submitted has revealed the following:
          </Text>
          <Text className='pl-6'>{alertFeedback}</Text>
          <Text>
            If you have any questions, please feel free to send an email to
            members@releaserent.com. You can also contact us by phone at (701)
            515-9759. We will be happy to assist you.
          </Text>
        </Flex>
      </InfoField>

      {/* Contact Information */}
      <InfoField title='Contact Us'>
        <ContactCard small className='mt-2' />
      </InfoField>
    </ClaimsInfoLayout>
  );
};

export default ClaimsDetailAlert;
