import { Card, Flex, Tag, Text } from '@chakra-ui/react';
import Button from 'common/components/button';

const PaymentPlanCard = ({
  type = 'Monthly Payments',
  price,
  onClick,
  theme,
  title = 'Choose Payment Option',
}) => {
  return (
    <Card className='relative pb-8 py-10 px-6 gap-6 w-full rounded-lg border border-slate-200'>
      {/* cost section */}
      <Flex className='w-full items-end gap-3'>
        <Flex className='w-full flex-col'>
          <Text className='text-2xl font-bold'>{type}</Text>
          <Text>
            {type == 'Monthly Payments'
              ? 'Pay in monthly installments'
              : 'Pay upfront for a discount'}
          </Text>
        </Flex>

        {/* Price section */}
        <Flex className='flex-col gap-2 relative'>
          {type != 'Monthly Payments' && (
            <Tag className='absolute -top-8 flex py-1 px-4 rounded-full bg-slate-100 text-sm font-semibold uppercase tracking-wide'>
              Best Deal
            </Tag>
          )}
          <Flex className='items-end gap-2'>
            <Text className='text-3xl font-medium'>{price}</Text>
            <Text className='pb-1'>/mo</Text>
          </Flex>
        </Flex>
      </Flex>

      {/* CTA section */}
      {/* Hidden if onClick isn't specified */}
      {onClick && <Button onClick={onClick} title={title} theme={theme} />}
    </Card>
  );
};

export default PaymentPlanCard;
