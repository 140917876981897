import { useNavigateSearch } from 'hooks/useNavigateSearch';
import ClaimsInfoLayout from 'layouts/claims-info';
import {
  formatDateToHumanDate,
  formatDateToHumanTime,
} from 'common/utils/formDateToHumanTime';
import Button from 'common/components/button';
import { Flex, Text } from '@chakra-ui/react';
import { InfoField } from 'common/components/info-card';
import ContactCard from 'components/shared/contact-card';

const ClaimsDetailAction = ({
  claim,
  amount = '6 months rent or $30,000',
  deductible = '500',
  showButton = true,
}) => {
  // HOOKS
  const navigateSearch = useNavigateSearch();

  // Data Initialization
  const address = claim?.planId?.lease?.address;
  const name =
    claim?.userId?.name ||
    `${claim?.userId?.firstName} ${claim?.userId?.lastName}`;
  const moveOutDate = claim?.moveOutDate;

  const claimID = claim?.id;
  const planID = claim?.planId.id;

  const approvedOn = claim?.approvedFeedback?.moveOutDate;
  const expiresOn =
    claim?.approvedFeedback?.moveOutDate + 7 * 24 * 60 * 60 * 1000;
  const deductibleAmount = deductible || claim?.approvedFeedback?.amountOwed;

  return (
    <ClaimsInfoLayout title='Claim Approved'>
      <Flex className='flex mobile:flex-col w-full gap-8 mobile:gap-4'>
        <InfoField title='Plan Number'>{claim?.planId.id}</InfoField>
        <InfoField title='Claim Number'>{claim?.id}</InfoField>
        <InfoField title='Submitted On'>
          {formatDateToHumanTime(claim?.submissionAt)}
        </InfoField>
      </Flex>

      <Flex className='flex mobile:flex-col w-full gap-8 mobile:gap-4'>
        <InfoField title='Cancellation Date'>
          {formatDateToHumanDate(moveOutDate)}
        </InfoField>
        <InfoField title='Approved On'>
          {formatDateToHumanTime(approvedOn)}
        </InfoField>
        <InfoField title='Expires On'>
          {formatDateToHumanTime(expiresOn)}
        </InfoField>
        <InfoField title='Deductible'>${deductibleAmount}</InfoField>
        <InfoField title='Coverage Amount'>Up to {amount}</InfoField>
      </Flex>

      {/* Standard Claims Approval Text */}
      <InfoField title='Detailed Feedback'>
        <Flex className='flex-col w-full gap-3 p-6 border border-slate-200 rounded-md mt-2'>
          {/* <Text>
            Dear {name},
          </Text> */}
          <Text>
            Thank you for submitting a claim for your apartment at{' '}
            {address?.streetAddress1}, {address?.streetAddress2},{' '}
            {address?.city}, {address?.state}, {address?.postalCode}.
          </Text>
          <Text>
            We have reviewed your recent claim regarding your proposed move-out
            date on {formatDateToHumanDate(claim?.moveOutDate)}.
          </Text>
          <Text>
            We have approved your claim for up to the lesser of {amount} with a
            deductible of ${deductibleAmount}.
          </Text>
          {showButton && (<Text>To confirm your claim, please click the button below.</Text>)}          
          <Text>
            If you have any questions, please feel free to send an email to
            members@releaserent.com. You can also contact us by phone at (701)
            515-9759. We will be happy to assist you.
          </Text>
        </Flex>
      </InfoField>

      {/* confirm claim button */}
      {showButton && (
        <Button
          onClick={() => {
            navigateSearch('/dashboard/apply-for-claims', {
              claimID,
              planID,
              step: 5,
              deductible,
              moveOutDate,
            });
          }}
          title='Confirm Claim'
        />
      )}      

      {/* Contact Information */}
      <InfoField title='Contact Us'>
        <ContactCard small className='mt-2' />
      </InfoField>
    </ClaimsInfoLayout>
  );
};

export default ClaimsDetailAction;
