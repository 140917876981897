import WrapInputLabel from 'components/shared/wrap-input-label';
import { statusEnum } from 'utils/enums';
import ApplyClaimsButtons from 'layouts/appy-claims-buttons';
import { useGetAllPlansQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { formatDateToHumanTime } from 'common/utils/formDateToHumanTime';
import { isEmpty } from 'common/utils/isEmpty';
import FormError from 'components/shared/form-error';
import Input from 'components/shared/input';
import SelectInput from 'components/shared/select-input';

const StartClaim = ({
  onNext,
  stepNumber,
  values,
  handleChange,
  setFieldValue,
  handleBlur,
  setFieldTouched,
}) => {
  // HOOKS
  const { data: plansData, isLoading: getPlansLoading } = useGetAllPlansQuery();

  // HANDLERS
  const createPlansOptionMenu = (obj) => {
    if (isEmpty(obj)) return [];

    return obj?.map(
      // eslint-disable-next-line no-unused-vars
      ({ createdAt, id, status, lease, ...res }) => ({
        label: `${id.toUpperCase()} - ${lease?.address?.streetAddress1}, ${lease?.address?.city} - ${status.toUpperCase()}`,
        value: id,
      })
    );
  };

  return (
    <div className='w-full h-full flex flex-col justify-between gap-8'>
      {/* inputs */}
      <div className='flex flex-col gap-5 w-full'>
        {/* select plan */}
        <div className='flex flex-col gap-2'>
          <WrapInputLabel isRequired={true}>
            <label className='text-[#191923] text-lg font-medium'>
              Select Plan:
            </label>
          </WrapInputLabel>

          <SelectInput
            options={createPlansOptionMenu(plansData)}
            isDisabled={isEmpty(plansData) || getPlansLoading}
            placeholder={
              getPlansLoading
                ? 'Fetching...'
                : isEmpty(plansData)
                ? `No available plan, please create a new one.`
                : 'Find your plan'
            }
            name='planId'
            value={createPlansOptionMenu(plansData).find(
              (selectedPlan) => selectedPlan.value === values.planId
            )}
            onChange={(selectedOption) => {
              setFieldValue('planId', selectedOption?.value || '').then(() => {
                setFieldTouched('planId');
              });
            }}
            onBlur={handleBlur}
            isOptionDisabled={(option) => {
              const optionID = option.value;
              const optionFullObject = plansData.find(
                (plan) => plan.id === optionID
              );
              return optionFullObject?.status !== statusEnum.active;
            }}
          />
          <FormError name='planId' />
        </div>

        {/* cancellation date */}
        <div className='flex flex-col gap-2'>
          <WrapInputLabel isRequired={true}>
            <label className='text-[#191923] text-lg font-medium'>
              Cancellation Date:
            </label>
          </WrapInputLabel>

          <Input
            placeholderText='Set a cancellation date'
            type='date'
            name='moveOutDate'
            value={values.moveOutDate}
            onBlur={handleBlur}
            onChange={(e) => {
              setFieldTouched('moveOutDate');
              handleChange(e);
            }}
          />
          <FormError name='moveOutDate' />
        </div>
      </div>

      {/* button */}
      <ApplyClaimsButtons
        disabledNextBtn={isEmpty(values.planId) || isEmpty(values.moveOutDate)}
        moveOn={true}
        onNextClick={onNext}
        stepNumber={stepNumber}
      />
    </div>
  );
};

export default StartClaim;
