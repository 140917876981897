import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Card,
  Divider,
  Flex,
  HStack,
  Icon,
  Skeleton,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import AnchorLinkTag from 'components/shared/anchor-link-tag';
import Button from 'common/components/button';
import { FaCheckCircle, FaExternalLinkAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetSinglePlanQuery,
  useRetrieveUserDetailsQuery,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import {
  savePlanPaymentMode,
  selectPlansValues,
} from 'redux/features/plans/plansSlice';
import { currency, planPaymentMode } from 'utils/enums';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { isEmpty } from 'common/utils/isEmpty';
import { currencyFormatter } from 'utils/currency';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import {
  FaCircleXmark,
  FaClipboard,
  FaCopy,
  FaEnvelope,
  FaPhone,
  FaStar,
} from 'react-icons/fa6';
import ClipboardButton from 'components/shared/clipboard-button';
import PlanInfoCard from '../plan-info-card';
import { setUserDetails } from 'redux/features/auth/authSlice';
import StatusLabel from 'components/shared/status-label';
import { toast } from 'react-toastify';
import PaymentPlanCard from '../payment-plan-card';
import ContactCard from 'components/shared/contact-card';
import DisclosureCard from '../disclosure-card';
import CoverageCard from '../coverage-card';

const PlanStepTwo = ({ onBackClick, onNextClick, isGettingPlan }) => {
  // HOOKS
  const { trackPageLoad, trackCustomEvent } = useUserTracker();
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();

  // Copy to Clipboard
  const copyToClipboard = (text, name) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast.success(`Copied ${name || text} to clipboard`);
      },
      (err) => {
        console.error('Failed to copy text: ', err);
      }
    );
  };

  // SIDE EFFECTS
  useEffect(() => {
    if (isRetrieveUserDetailsSuccess && !isEmpty(userDetails)) {
      dispatch(setUserDetails(userDetails));
    }
  }, []);
  // For some reason I can't use the tracker element, causes weird page loading issues
  useEffect(() => {
    trackPageLoad('planStepTwo');
  }, []);

  // DATA INITIALIZATION
  const dispatch = useDispatch();
  const plansValue = useSelector(selectPlansValues);
  const address = plansValue?.lease?.address || {};
  const { data: singlePlan, isLoading: isGettingSinglePlan } =
    useGetSinglePlanQuery(plansValue?.id, {
      skip: isEmpty(plansValue?.id),
    });

  let plan = singlePlan || plansValue;
  const planId = plansValue?.id || singlePlan?.id || 'unknown';
  const fmtter = currencyFormatter(plan?.lease?.currency);
  const oneTimePayment = fmtter.format(plan?.payment?.oneTimePayment);
  const oneTimeMonthlyAmount = fmtter.format(plan?.payment?.oneTimeMonthlyAmt);
  const oneTimeSaveAmount = fmtter.format(
    plan?.payment?.monthlyPayment - plan?.payment?.oneTimeMonthlyAmt
  );
  const monthlyAmount = fmtter.format(plan?.payment?.monthlyPayment);

  return (
    <Flex w='100%' flexDir='column' gap={10}>
      {isGettingSinglePlan || isGettingPlan ? (
        <Flex flexDir='column' gap='20px'>
          <Skeleton rounded='10px' h='10dvh' w='100%' />
          <Flex w='100%' gap='20px'>
            <Skeleton rounded='10px' h='40dvh' w='100%' />
            <Skeleton rounded='10px' h='40dvh' w='100%' />
          </Flex>
          <Skeleton rounded='10px' h='40dvh' w='100%' />
          <Skeleton rounded='10px' h='40dvh' w='100%' />
        </Flex>
      ) : isEmpty(singlePlan) ? (
        <Flex
          border='1px solid'
          borderColor='gray.300'
          rounded='12px'
          w='100%'
          alignItems='center'
          justifyContent='center'
          h='60dvh'
        >
          <Text fontWeight={600} fontSize='20px'>
            No plan found!
          </Text>
        </Flex>
      ) : (
        <>
          {/* container */}
          <Flex className='w-full flex-col gap-10'>
            {/* plan card and info */}
            <Flex className='flex-col gap-6'>
              {/* plan heading */}
              <Flex className='flex-col gap-2 w-full items-left'>
                <h2 className='text-2xl font-bold'>Review Plan Details</h2>
                <Text>
                  Please review the plan details below to ensure they match your
                  lease agreement. If any changes are needed, click the button
                  below to go back and update your lease information.
                </Text>
              </Flex>

              {/* plan info card */}
              <Flex className='flex-col gap-4 w-full'>
                <PlanInfoCard
                  streetAddress1={address?.streetAddress1}
                  streetAddress2={address?.streetAddress2}
                  city={address?.city}
                  state={address?.state}
                  country={address?.country}
                  postalCode={address?.postalCode}
                  name={
                    userDetails?.name ||
                    `${userDetails?.firstName} ${userDetails?.lastName}`
                  }
                  startDate={singlePlan?.lease?.startDate}
                  endDate={singlePlan?.lease?.endDate}
                  planId={singlePlan?.id}
                  inactive
                />
                <Button
                  onClick={onBackClick}
                  theme='secondary'
                  title='Update Lease Information'
                />
              </Flex>
            </Flex>

            {/* plan coverages section */}
            <Flex className='flex-col gap-6 mt-6'>
              {/* coverages heading */}
              <Flex className='flex-col gap-2 w-full items-left'>
                <h2 className='text-2xl font-bold'>Your Plan Coverages</h2>
                <Text>
                  Your plan includes the following coverages, limits,
                  deductibles, and exclusions.
                </Text>
              </Flex>

              <DisclosureCard />

              {/* Limits */}
              <CoverageCard>
                The coverage limit is the maximum amount that can be paid for a covered cancellation. For example, if you have a limit of 6 months, the maximum amount that will be covered for a cancellation is 6 months of rent.
              </CoverageCard>

              {/* Deductibles */}
              <CoverageCard
                title='Deductible'
                description='The deductible for this plan is the lesser of:'
                limit1='$500'
                limit2='one months rent'
              >
                A deductible is the amount you pay out-of-pocket for covered cancellations before your coverage starts to pay. For example, if you have a $500 deductible, you would pay the first $500 of covered expenses for a cancellation.
              </CoverageCard>

              {/* Elimination Period */}
              <CoverageCard
                title='Elimination Period'
                description='The elimination period is the greater of:'
                limit1='one month'
                limit2='1/12 of the total lease term'
              >
                The elimination period is the number of days you must wait after a cancellation is submitted before benefits are paid out. For example, if you have a one month elimination period, you would wait one month after a cancellation is filed before any benefits are paid out.
              </CoverageCard>

            </Flex>

            {/* payment cards */}
            {/* plan heading and status */}
            <Flex className='flex-col gap-2 w-full mt-6'>
              <h2 className='text-2xl font-bold'>Select a Payment Plan</h2>
              <Text>
                Choose between paying the full amount upfront with an 8.33%
                discount or making monthly payments. Monthly payments begin on
                your lease start date if purchased early, or immediately if
                purchased after.
              </Text>
            </Flex>

            <Flex className='mobile:flex-col' gap='20px'>
              {/* one time card */}
              <PaymentPlanCard
                price={oneTimeMonthlyAmount}
                onClick={() => {
                  trackCustomEvent('planSelected', {
                    paymentType: 'one-time',
                    planId,
                  });
                  dispatch(savePlanPaymentMode(planPaymentMode.ONE_TIME));
                  onNextClick();
                }}
                type='Upfront Payment'
                title='Choose Upfront Payment'
              />

              {/* monthly card */}
              <PaymentPlanCard
                price={monthlyAmount}
                onClick={() => {
                  trackCustomEvent('planSelected', {
                    paymentType: 'monthly',
                    planId,
                  });
                  dispatch(savePlanPaymentMode(planPaymentMode.MONTHLY));
                  onNextClick();
                }}
                type='Monthly Payments'
                title='Choose Monthly Payments'
              />
            </Flex>

            <ContactCard />
          </Flex>
        </>
      )}
      {/* Buttons */}
      {/* <ApplyPlansButtons */}
      {/*   moveOn={true} */}
      {/*   disableBackBtn={isEmpty(singlePlan)} */}
      {/*   isValid={!isEmpty(singlePlan)} */}
      {/*   onNextClick={onNextClick} */}
      {/*   stepNumber={stepNumber} */}
      {/*   onBackClick={onBackClick} */}
      {/* /> */}
    </Flex>
  );
};

export default PlanStepTwo;
