const EmptyClaims = () => {
  return (
    <div className="w-full flex flex-col items-center">
      <div className='w-full flex flex-col gap-4 items-center py-20 text-center max-w-[500px]'>
        <img
          src='/images/dashboard/empty-claims.svg'
          alt='empty claims'
          className='h-60 pb-4'
        />

        <p className='text-[#191923] text-4xl font-bold'>Need Help Getting Started?</p>

        <p className='text-base text-[#191923] font-normal w-full'>
          {`Start a new claim by pressing the "Start a New Claim" button.`}
        </p>
        <p className='text-base text-[#191923] font-normal w-full'>
          {`Alternatively, call +1 (701) 515-9759 or email members@releaserent.com for assistance. Plan support is available 5:00 am to 6:00 pm (PT), Monday to Saturday. Our claims department is available 24 hours a day.`}
        </p>
      </div>
    </div>
  );
};

export default EmptyClaims;
