import { FaCheck, FaCircleCheck } from 'react-icons/fa6';
import { IoMdInformationCircle } from 'react-icons/io';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  useConfirmAClaimMutation,
  useGetSingleClaimQuery,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import Button from 'common/components/button';
import { Flex, Text } from '@chakra-ui/react';
import ClaimsDetailInfo from 'components/claims/claims-detail-info';
import ClaimsDetailAction from 'components/claims/claims-detail-action';

const StartClaimApprove = ({ isApprovedClaim, setIsApprovedClaim }) => {
  // HOOKS
  const [confirmClaim, { isLoading: confirmClaimLoading }] =
    useConfirmAClaimMutation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const claimId = searchParams.get('claimID');
  const { data: values, isLoading: getSingleClaimLoading } =
    useGetSingleClaimQuery(claimId);

  console.log('values', values);

  // HANDLERS
  const handleGoToDashboard = () => {
    navigate(`/dashboard/claims/${claimId}`);
  };

  if (isApprovedClaim) {
    return (
      <div className='w-full flex flex-col justify-between gap-8 h-full'>
        {/* Approved claim details card */}
        <ClaimsDetailInfo claim={values} />

        {/* button */}
        <div className='w-full flex flex-col gap-5'>
          <Button onClick={handleGoToDashboard} title='Return to Claim Details' />
        </div>
      </div>
    );
  } else {
    return (
      <div className='w-full flex flex-col gap-10'>
        {/* Approved claim details card */}
        <ClaimsDetailAction claim={values} showButton={false} />

        {/* confirmation disclaimer */}
        <Flex className='flex-col gap-2 w-full'>
          <Text>
          By confirming this claim, you agree that payment for the deductible, as stated above, will be debited from your payment method on file. Once confirmed, your approved move-out date, as stated above, will been finalized with your property manager or landlord, and you will be required to vacate the apartment before this date. Once your claim is confirmed, it will be final and cannot be modified or changed.
          </Text>
        </Flex>

        {/* buttons */}
        <div className='w-full flex flex-col gap-5'>
          <Button
            loading={confirmClaimLoading}
            onClick={() => {
              confirmClaim(claimId)
                .unwrap()
                .then(() => {
                  setIsApprovedClaim(true);
                  toast.info(`Claim confirmed successfully`, {
                    toastId: 'confirmClaimSuccess1',
                  });
                })
                .catch((error) => {
                  console.error(error);
                  handleGoToDashboard();
                  toast.error(error?.message || `Couldn't confirm the claim`, {
                    toastId: 'confirmClaimError1',
                  });
                });
            }}
            title='Confirm Claim'
          />
          <Button
            onClick={handleGoToDashboard}
            title='Do Not Confirm Claim'
            theme='secondary'
          />
        </div>
      </div>
    );
  }
};

export default StartClaimApprove;
