import { IoIosArrowBack } from 'react-icons/io';
import {
  Flex,
  Text,
  Card,
  Divider,
  HStack,
  Skeleton,
  Icon,
  useClipboard,
  Heading,
} from '@chakra-ui/react';
import { RiArrowDownSFill } from 'react-icons/ri';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { Link, useParams } from 'react-router-dom';
import DocumentPreview from 'components/shared/document-preview';
import EmptyData from 'components/shared/empty-data';
import LoadingSpinner from 'components/shared/loading-spinner';
import StatusLabel from 'components/shared/status-label';
import DashboardMainLayout from 'layouts/dashboard-main';
import { useGetSinglePlanQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { formatDateToHumanTime } from 'common/utils/formDateToHumanTime';
import { isEmpty } from 'common/utils/isEmpty';
import DownloadPdfButton from 'components/plans/download-button';
import PaymentSummary from 'components/plans/payment-summary';
import { FaCopy } from 'react-icons/fa6';
import ClipboardButton from 'components/shared/clipboard-button';
import AnchorLinkTag from 'components/shared/anchor-link-tag';
import Button from 'common/components/button';
import PlanInfoCard from 'components/plans/plan-info-card';
import { useEffect } from 'react';
import { useRetrieveUserDetailsQuery } from 'redux/api/ciosUsersApi/ciosUsersApi';
import { useDispatch } from 'react-redux';
import { setUserDetails } from 'redux/features/auth/authSlice';
import DisclosureCard from 'components/plans/disclosure-card';
import CoverageCard from 'components/plans/coverage-card';
import PaymentSummaryCard from 'components/plans/payment-summary-card';
import { planPaymentMode } from 'utils/enums';
import ContactCard from 'components/shared/contact-card';

const PlanDetails = () => {
  // HOOKS
  const { planId } = useParams();
  const dispatch = useDispatch();
  const { data: singlePlan, isLoading: getSinglePlanLoading } =
    useGetSinglePlanQuery(planId);
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();

  // SIDE EFFECTS
  useEffect(() => {
    if (isRetrieveUserDetailsSuccess && !isEmpty(userDetails)) {
      dispatch(setUserDetails(userDetails));
    }
  }, []);

  console.log('Plan details', singlePlan);

  return (
    <DashboardMainLayout title={`Plan Details`}>
      <div className='flex flex-col w-full gap-6'>
        {/* breadcrumb */}
        <div className='flex gap-3 items-center'>
          <Link
            to='/dashboard/home'
            className='font-normal text-base text-[#CED0CE]'
          >
            Home
          </Link>
          <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
          <Link
            to='/dashboard/plans'
            className='text-[#CED0CE] text-base font-normal'
          >
            Plans{' '}
          </Link>
          <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
          <Link to='' className='text-[#191923] text-base font-normal'>
            {singlePlan?.id}
          </Link>
        </div>
        {/* mobile: back button */}
        {/* <Link
          to='/dashboard/plans'
          className='text-[#191923] text-sm font-medium hidden mobile:inline-flex items-center gap-2'
        >
          <IoIosArrowBack className='text-xl text-[#9CA3AF]' />
          Back
        </Link> */}

        {/* buttons */}
        <div className='flex w-full gap-4 mobile:flex-col'>
          <Link className='w-full' to='/dashboard/apply-for-claims'>
            <Button title='Start a New Claim' />
          </Link>
          <AnchorLinkTag
            className='w-full'
            hrefPath='mailto:members@releaserent.com'
          >
            <Button theme='secondary' title='Chat with an Expert' />
          </AnchorLinkTag>
        </div>

        {/* plan card and payment summary */}
        {getSinglePlanLoading ? (
          <LoadingSpinner />
        ) : isEmpty(singlePlan) ? (
          <EmptyData />
        ) : (
          <div className='w-full gap-5 mobile:bg-white'>
            <Flex className='w-full flex-col gap-10'>
              {/* plan card and info */}
              <Flex className='flex-col gap-6 mt-6'>
                {/* plan heading and status */}
                <Flex className='flex-col gap-4 w-full'>
                  <Flex className='gap-4 items-center justify-between'>
                    <h2 className='text-2xl font-bold'>Your Plan Details</h2>
                    <StatusLabel status={singlePlan?.status} />
                  </Flex>
                </Flex>

                {/* plan info card */}
                <Flex className='flex-col gap-6 w-full mb-6'>
                  <PlanInfoCard
                    streetAddress1={singlePlan?.lease?.address?.streetAddress1}
                    streetAddress2={singlePlan?.lease?.address?.streetAddress2}
                    city={singlePlan?.lease?.address?.city}
                    state={singlePlan?.lease?.address?.state}
                    country={singlePlan?.lease?.address?.country}
                    postalCode={singlePlan?.lease?.postalCode}
                    name={
                      userDetails?.name ||
                      `${userDetails?.firstName} ${userDetails?.lastName}`
                    }
                    startDate={singlePlan?.lease?.startDate}
                    endDate={singlePlan?.lease?.endDate}
                    planId={singlePlan?.id}
                  />

                  <Flex className='flex-col gap-4'>
                    {singlePlan?.id && (
                      <DownloadPdfButton planId={singlePlan?.id} />
                    )}
                    <AnchorLinkTag
                      className='w-full'
                      hrefPath='https://www.releaserent.com/legal/release-leave-anytime-terms-conditions'
                    >
                      <Button
                        theme='secondary'
                        title='View Product Terms & Conditions'
                      />
                    </AnchorLinkTag>
                  </Flex>
                </Flex>

                <PaymentSummaryCard
                  plan={singlePlan}
                  complete={true}
                  paymentMode={planPaymentMode}
                />
              </Flex>

              {/* Contact Us Card */}
              <ContactCard
                title='Contact Us'                
              >
                Questions about your plan or with claims?. Contact us by email, phone, or text and we can help you answer any questions you may have about purchasing a plan, making claims, and more.
              </ContactCard>

              {/* plan coverages section */}
              <Flex className='flex-col gap-6 mt-6'>
                {/* coverages heading */}
                <Flex className='flex-col gap-2 w-full items-left'>
                  <h2 className='text-2xl font-bold'>Your Plan Coverages</h2>
                  <Text>
                    Your plan includes the following coverages, limits,
                    deductibles, and exclusions.
                  </Text>
                </Flex>

                <DisclosureCard />

                {/* Limits */}
                <CoverageCard>
                  The coverage limit is the maximum amount that can be paid for
                  a covered cancellation. For example, if you have a limit of 6
                  months, the maximum amount that will be covered for a
                  cancellation is 6 months of rent.
                </CoverageCard>

                {/* Deductibles */}
                <CoverageCard
                  title='Deductible'
                  description='The deductible for this plan is the lesser of:'
                  limit1='$500'
                  limit2='one months rent'
                >
                  A deductible is the amount you pay out-of-pocket for covered
                  cancellations before your coverage starts to pay. For example,
                  if you have a $500 deductible, you would pay the first $500 of
                  covered expenses for a cancellation.
                </CoverageCard>

                {/* Elimination Period */}
                <CoverageCard
                  title='Elimination Period'
                  description='The elimination period is the greater of:'
                  limit1='one month'
                  limit2='1/12 of the total lease term'
                >
                  The elimination period is the number of days you must wait
                  after a cancellation is submitted before benefits are paid
                  out. For example, if you have a one month elimination period,
                  you would wait one month after a cancellation is filed before
                  any benefits are paid out.
                </CoverageCard>
              </Flex>
            </Flex>
          </div>
        )}
      </div>
    </DashboardMainLayout>
  );
};

export default PlanDetails;
