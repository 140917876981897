import React from 'react';
import {
  Card,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Text,
  Flex,
} from '@chakra-ui/react';
import PaymentSummary from 'components/plans/payment-summary';
import { currency, planPaymentMode } from 'utils/enums';

const PaymentSummaryCard = ({ plan, complete = false, paymentMode = true }) => {

  const isMonthly =
    plan?.payment.choice === 'monthly' ||
    paymentMode === planPaymentMode.MONTHLY;
  
  return (
    <Card className='flex flex-col rounded-lg border border-slate-200'>
      <Accordion
        allowMultiple
        className='flex w-full flex-col  rounded-md overflow-hidden'
      >
        <AccordionItem className='border-none'>
          {/* title */}

          <div className='capitalize p-6 flex mobile:flex-col gap-6 justify-between items-center mobile:items-start'>
            <div>
              <h2 className='text-2xl font-bold'>Payment Plan Details</h2>
              <Text>{isMonthly ? 'Monthly' : 'Upfront'} Payment Plan</Text>
            </div>
            <Flex className='gap-5 divide-x divide-slate-200 items-center'>
              <div className='flex flex-col items-end mobile:items-start'>
                <div className=' text-2xl font-medium'>
                  $
                  {isMonthly
                    ? plan?.payment.monthlyPayment
                    : plan?.payment.oneTimePayment}{' '}
                  {plan?.lease?.currency}
                </div>
                <div className='text-base font-normal'>
                  {complete ? 'Paid Upfront' : 'Due Today'}
                </div>
              </div>
              {isMonthly && (
                <div className='flex flex-col items-end mobile:items-start pl-4'>
                  <div className=' text-2xl font-medium'>
                    ${plan?.payment.monthlyPayment}{' '}
                    {plan?.lease?.currency}
                  </div>
                  <div className='text-base font-normal'>Due Monthly</div>
                </div>
              )}
            </Flex>
          </div>
          <AccordionButton className='px-6 py-3 hover:bg-slate-100 border-t border-slate-200'>
            <div className='w-full text-left text-sm font-semibold py-1 uppercase'>
              Show Expanded Payment Plan Details
            </div>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel className='border-t p-6 flex flex-col gap-8'>
            {/* payment information */}
            <PaymentSummary plan={plan} complete={complete} border={false} />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Card>
  );
};

export default PaymentSummaryCard;
