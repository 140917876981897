/* eslint-disable no-unused-vars */
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { statusEnum } from 'utils/enums';
import {
  formatDateToHumanDate,
  formatDateToHumanTime,
} from 'common/utils/formDateToHumanTime';
import StatusLabel from 'components/shared/status-label';
import { Icon } from '@chakra-ui/react';

const PlansTable = ({ plans }) => {
  // DATA INTIAILIZATION
  const navigate = useNavigate();
  const excludedStatuses = ['cancelled', 'complete', 'active'];

  // HANDLERS
  const getNavURL = (status, id, step) => {
    let navigationUrl = '';
    if (
      (step < 5 && status === statusEnum.active) ||
      (step < 5 && status === statusEnum.complete) ||
      (step < 5 && status === statusEnum.rejected) ||
      (step < 5 && status === statusEnum.cancelled)
    ) {
      navigationUrl = '';
    } else if (+step === 5) {
      navigationUrl = `/dashboard/plans/${id}`;
    } else if (+step === 4) {
      navigationUrl = `/dashboard/apply-for-plans?step=${step}&status=${status}&isSubmitted=true&planId=${id}`;
    } else {
      navigationUrl = `/dashboard/apply-for-plans?step=${step}&status=${status}&isSubmitted=false&planId=${id}`;
    }
    return navigationUrl;
  };
  const isValidStatus = (status) => !excludedStatuses.includes(status);  

  return (
    <table className='flex flex-col w-full'>

      {/* body */}
      <tbody className='w-full rounded-xl border border-gray-200 divide-y'>
        {plans.map((plan, index) => (
          isValidStatus(plan.status) && 
          <>
          <tr
            key={index}
            onClick={() =>
              navigate(getNavURL(plan.status, plan.id, plan?.step))
            }
            className='grid grid-cols-[1fr_auto_210px] gap-4 w-full hover:bg-gray-50 px-3 py-2 cursor-pointer mobile:flex mobile:flex-col justify-center'
          >             

            {/* address, start date, end date */}
            <td className='flex flex-col gap-1 w-full p-3 text-[#191923] text-base font-medium items-start'>
              <span className='uppercase tracking-wider font-bold text-sm' >
                Address
              </span>
              <span className='uppercase tracking-wider text-lg'>
              {plan?.lease?.address?.streetAddress1}{plan?.lease?.address?.streetAddress2 && ', '}{plan?.lease?.address?.streetAddress2}, {plan?.lease?.address?.city}, {plan?.lease?.address?.state}, {plan?.lease?.address?.postalCode}
              </span>
            </td>

            {/* coverage period */}
            <td className='flex flex-col gap-1 w-full p-3 text-[#191923] text-base font-medium items-start'>
              <span className='uppercase tracking-wider font-bold text-sm' >
                Coverage Period
              </span>
              <div className='flex gap-1'>
              <span className='uppercase tracking-wider text-lg'>
              {formatDateToHumanDate(plan?.lease?.startDate || '')}
              </span>
              <span className='block'>
                {' - '}
              </span>
              <span className='uppercase tracking-wider text-lg'>
              {formatDateToHumanDate(plan?.lease?.endDate || '')}
              </span>
              </div>
            </td>

            {/* status */}
            <td className='w-full p-3 flex flex-col gap-1 justify-center'>
              <div className=' inline-flex justify-between w-full items-center'>
              
              <StatusLabel status={plan.status} size='sm' addedText={plan?.step && `Step ${plan?.step} / 5`}/>

                {plan?.status === statusEnum.cancelled ? null : (
                  <Icon as={MdKeyboardArrowRight} boxSize={4} className='mobile:hidden'/>
                )}
              </div>
            </td>
          </tr>
          </>
        ))}
      </tbody>
    </table>
  );
};

export default PlansTable;
