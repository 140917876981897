import {
  Button,
  Card,
  Divider,
  Flex,
  HStack,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import StatusLabel from 'components/shared/status-label';
import DownloadPdfButton from 'components/plans/download-button';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  useGetSinglePlanQuery,
  useRetrieveUserDetailsQuery,
} from 'redux/api/ciosUsersApi/ciosUsersApi';
import {
  clearPlansDraft,
  selectPaymentMode,
  selectPlansValues,
} from 'redux/features/plans/plansSlice';
import { currency, planPaymentMode, statusEnum } from 'utils/enums';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import { isEmpty } from 'common/utils/isEmpty';
import { currencyFormatter } from 'utils/currency';
import PaymentSummary from 'components/plans/payment-summary';
import ClipboardButton from 'components/shared/clipboard-button';
import AnchorLinkTag from 'components/shared/anchor-link-tag';
import PlanInfoCard from 'components/plans/plan-info-card';
import { setUserDetails } from 'redux/features/auth/authSlice';
import PaymentSummaryCard from 'components/plans/payment-summary-card';
import DisclosureCard from 'components/plans/disclosure-card';
import CoverageCard from 'components/plans/coverage-card';

const PlanStepFour = ({
  stepNumber,
  onBackClick,
  onNextClick,
  isGettingPlan,
}) => {
  const { trackPageLoad, trackLink } = useUserTracker();
  useEffect(() => {
    trackPageLoad('planStepFour');
  }, []);

  // SIDE EFFECTS
  useEffect(() => {
    if (isRetrieveUserDetailsSuccess && !isEmpty(userDetails)) {
      dispatch(setUserDetails(userDetails));
    }
  }, []);

  // HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentMode = useSelector(selectPaymentMode);
  const plansValue = useSelector(selectPlansValues);
  const { data: singlePlan, isLoading: isGettingSinglePlan } =
    useGetSinglePlanQuery(plansValue?.id, {
      skip: isEmpty(plansValue?.id),
    });
  const { isSuccess: isRetrieveUserDetailsSuccess, data: userDetails } =
    useRetrieveUserDetailsQuery();

  return (
    <Flex w='100%' flexDir='column' gap={10}>
      {isGettingSinglePlan || isGettingPlan ? (
        <Flex flexDir='column' gap='20px'>
          <Skeleton rounded='10px' h='10dvh' w='100%' />
          <Flex w='100%' gap='20px'>
            <Skeleton rounded='10px' h='40dvh' w='100%' />
            <Skeleton rounded='10px' h='40dvh' w='100%' />
          </Flex>
          <Skeleton rounded='10px' h='40dvh' w='100%' />
          <Skeleton rounded='10px' h='40dvh' w='100%' />
        </Flex>
      ) : isEmpty(singlePlan) ? (
        <Flex
          border='1px solid'
          borderColor='gray.300'
          rounded='12px'
          w='100%'
          alignItems='center'
          justifyContent='center'
          h='60dvh'
        >
          <Text fontWeight={600} fontSize='20px'>
            No plan found!
          </Text>
        </Flex>
      ) : (
        <>
          {/* container */}
          <Flex className='w-full flex-col gap-10'>
            {/* before activating plan */}
            <Flex className='flex-col gap-8 w-full p-6 rounded-lg border border-slate-200 border-solid'>
              {/* Before your plan is activated */}
              <Flex flexDir='column' w='100%' gap={1}>
                <h2 className='font-bold text-2xl text-[#191923]'>
                  {`Before Your Plan is Activated`}
                </h2>

                <Text fontSize='16px' fontWeight={400} textColor='#191923'>
                  {`Please confirm a few details to activate your plan. It'll only take you five minutes.`}
                </Text>
              </Flex>

              {/* ID VERIFICATION */}
              <Flex flexDir='column' w='100%' gap={1}>
                <Text fontSize='16px' fontWeight={700} textColor='#191923'>
                  {`VERIFY YOUR ID`}
                </Text>

                <Text fontSize='16px' fontWeight={400} textColor='#191923'>
                  {`We require a government-issued ID before we activate your plan to help prevent fraud. Your privacy and identification are handled securely.`}
                </Text>
              </Flex>

              {/* Lease Verification */}
              <Flex flexDir='column' w='100%' gap={1}>
                <Text fontSize='16px' fontWeight={700} textColor='#191923'>
                  {`VERIFY YOUR LEASE AGREEMENT`}
                </Text>

                <Text fontSize='16px' fontWeight={400} textColor='#191923'>
                  {`We require you upload a copy of your signed lease agreement to validate your lease details and to help prevent fraud.`}
                </Text>
              </Flex>

              <div className='w-full flex flex-col gap-4'>
                {/* Activate Plan */}
                <Button
                  onClick={() => {
                    trackLink('Activate Plan');
                    onNextClick();
                  }}
                  rounded='61px'
                  fontWeight={600}
                  linkId='activatePlan'
                  fontSize='16px'
                  size='lg'
                  border='1px solid'
                  color='#FBFEF9'
                  background='#191923'
                  borderColor='#191923'
                >
                  Activate Plan
                </Button>

                {/* Activate Plan Later */}
                <Button
                  onClick={() => {
                    trackLink('Activate Plan Later');
                    navigate('/dashboard/plans');
                    dispatch(clearPlansDraft());
                  }}
                  rounded='61px'
                  fontWeight={600}
                  fontSize='16px'
                  size='lg'
                  variant='ouline'
                  border='1px solid'
                  borderColor='gray.400'
                >
                  Activate Plan Later
                </Button>
              </div>
            </Flex>

            {/* plan card and info */}
            <Flex className='flex-col gap-6'>
              {/* plan heading and status */}
              <Flex className='flex-col gap-4 w-full'>
                <Flex className='gap-4 items-center justify-between'>
                  <h2 className='text-2xl font-bold'>Your Plan Details</h2>
                  <StatusLabel status={singlePlan?.status} />
                </Flex>
              </Flex>

              {/* plan info card */}
              <Flex className='flex-col gap-4 w-full'>
                <PlanInfoCard
                  streetAddress1={singlePlan?.lease?.address?.streetAddress1}
                  streetAddress2={singlePlan?.lease?.address?.streetAddress2}
                  city={singlePlan?.lease?.address?.city}
                  state={singlePlan?.lease?.address?.state}
                  country={singlePlan?.lease?.address?.country}
                  postalCode={singlePlan?.lease?.postalCode}
                  name={
                    userDetails?.name ||
                    `${userDetails?.firstName} ${userDetails?.lastName}`
                  }
                  startDate={singlePlan?.lease?.startDate}
                  endDate={singlePlan?.lease?.endDate}
                  planId={singlePlan?.id}
                  inactive
                />
              </Flex>

              <PaymentSummaryCard
                plan={singlePlan}
                complete={true}
                paymentMode={planPaymentMode}
              />
            </Flex>

            {/* plan coverages section */}
            <Flex className='flex-col gap-6 mt-6'>
              {/* coverages heading */}
              <Flex className='flex-col gap-2 w-full items-left'>
                <h2 className='text-2xl font-bold'>Your Plan Coverages</h2>
                <Text>
                  Your plan includes the following coverages, limits,
                  deductibles, and exclusions.
                </Text>
              </Flex>

              <DisclosureCard />

              {/* Limits */}
              <CoverageCard>
                The coverage limit is the maximum amount that can be paid for a
                covered cancellation. For example, if you have a limit of 6
                months, the maximum amount that will be covered for a
                cancellation is 6 months of rent.
              </CoverageCard>

              {/* Deductibles */}
              <CoverageCard
                title='Deductible'
                description='The deductible for this plan is the lesser of:'
                limit1='$500'
                limit2='one months rent'
              >
                A deductible is the amount you pay out-of-pocket for covered
                cancellations before your coverage starts to pay. For example,
                if you have a $500 deductible, you would pay the first $500 of
                covered expenses for a cancellation.
              </CoverageCard>

              {/* Elimination Period */}
              <CoverageCard
                title='Elimination Period'
                description='The elimination period is the greater of:'
                limit1='one month'
                limit2='1/12 of the total lease term'
              >
                The elimination period is the number of days you must wait after
                a cancellation is submitted before benefits are paid out. For
                example, if you have a one month elimination period, you would
                wait one month after a cancellation is filed before any benefits
                are paid out.
              </CoverageCard>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default PlanStepFour;
