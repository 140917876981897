import React, { useEffect } from 'react';
import { FaMoneyCheckDollar, FaTeamspeak, FaCircleInfo } from 'react-icons/fa6';
import AnchorLinkTag from 'components/shared/anchor-link-tag';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { IoMdInformationCircle } from 'react-icons/io';
import { IoIosSend, IoMdSettings } from 'react-icons/io';
import { RiErrorWarningFill } from 'react-icons/ri';
import { Link } from 'common/utils/user-tracker/react-router-dom';
import DashboardCard from '../../../components/shared/dashboard-card';
import DashboardMessage from 'components/shared/dashboard-message';
import DashboardMainLayout from '../../../layouts/dashboard-main';
import {
  useGetAllClaimsQuery,
  useGetAllPlansQuery,
  useGetDashboardMessagesQuery,
} from '../../../redux/api/ciosUsersApi/ciosUsersApi';
import {
  Box,
  Button,
  Flex,
  HStack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import useUserTracker from 'common/utils/user-tracker/useUserTracker';
import ContactCard from 'components/shared/contact-card';

const DashboardHome = () => {
  const { trackPageLoad, trackCustomEvent, setFormData } = useUserTracker();

  // For some reason I can't use the tracker element, causes weird page loading issues
  useEffect(() => {
    trackPageLoad('dashboardHome');
    setFormData({});
  }, []);
  // HOOKS
  const { data: claimsData, isLoading: isGetAllClaimsLoading } =
    useGetAllClaimsQuery();
  const { data: plansData, isLoading: getAllPlansLoading } =
    useGetAllPlansQuery();
  const { data: dashboardMessages, isLoading: getDashboardMessagesLoading } =
    useGetDashboardMessagesQuery();

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const textColor = useColorModeValue('gray.500', 'gray.300');

  return (
    <DashboardMainLayout title='Home'>
      {dashboardMessages?.message && (

        // July 25 2024 - Replaced Taylor's rough box with a proper chakra-ui implementation for the activate card
        <DashboardMessage 
          Icon={RiErrorWarningFill}
          path={`/dashboard/apply-for-plans?step=5&status=pending&isSubmitted=true&planId=${dashboardMessages.planID}`}
          title='Activate Plan'
          description={dashboardMessages.message}
        />
      )}

      <div className='w-full grid grid-cols-4 gap-8 mobile:grid-cols-2 mobile:gap-5 mb-6'>
        {/* Plans Card */}
        <DashboardCard
          loading={getAllPlansLoading}
          title='Plans'
          count={plansData?.length ?? 0}
          path='/dashboard/plans'
          Icon={IoIosSend}
        />

        {/* Claims Card */}
        <DashboardCard
          loading={isGetAllClaimsLoading}
          title='Claims'
          count={claimsData?.length ?? 0}
          path='/dashboard/claims'
          Icon={RiErrorWarningFill}
        />

        {/* settings */}
        <DashboardCard
          title='Settings'
          count={0}
          path='/dashboard/settings'
          Icon={IoMdSettings}
        />
      </div>
      
      {/* Contact Us Card */}
      <ContactCard 
        title='Contact Us'        
      >
        Questions about your plan or with claims?. Contact us by email, phone, or text and we can help you answer any questions you may have about purchasing a plan, making claims, and more.
      </ContactCard>

    </DashboardMainLayout>
  );
};

export default DashboardHome;
