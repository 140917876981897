import { Card, Text, Flex, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Icon } from "@chakra-ui/react"
import AnchorLinkTag from "components/shared/anchor-link-tag"
import { FaCircleXmark, FaCircleCheck } from "react-icons/fa6";

// Coverages and Exclusions props allow individual cards to be turned off if desired

const DisclosureCard = ({ coverages = true, exclusions = true }) => {
  return (
    <>
      {/* coverages */}
      {coverages && (
        <Card
          border='1px solid #E1E6EF'
          className='flex flex-col gap-4 px-6 py-8 rounded-lg'
        >
          <Flex className='flex-col gap-2'>
            <h2 className='text-2xl font-bold'>Covered Cancellation Costs</h2>
            <Text className='mb-4'>
              Your ReLease plan covers a variety of costs that you may be charged
              by your landlord as a result of cancelling your lease.{' '}
              <AnchorLinkTag
                className='underline'
                hrefPath='https://www.releaserent.com/legal/lease-flexibility-coverage-terms-conditions'
              >
                Learn more about what's covered in your plan
              </AnchorLinkTag>
              .
            </Text>
          </Flex>

          <Flex className='flex mobile:flex-col gap-8 w-full'>
            <Flex className='flex-col w-full gap-4 justify-start'>
              <h2 className='text-xl font-bold'>What's Covered</h2>
              <Accordion
                allowMultiple
                className='flex w-full flex-col border border-slate-200 rounded-md overflow-hidden'
              >
                <AccordionItem className='border-none'>
                  <h2>
                    <AccordionButton className='bg-slate-100 hover:bg-slate-200 px-3'>
                      <div className='inline-flex gap-4 w-full text-left text-sm font-semibold py-1 uppercase'>
                        <Icon as={FaCircleCheck} boxSize={4} />
                        Outstanding Rent
                      </div>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel className='border-t py-4'>
                    {/* Options */}
                    <div>
                      ReLease will cover any outstanding rent owed on the same
                      schedule as in your lease agreement until a replacement
                      tenant is found, up to your coverage limit.
                    </div>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem className='border-none'>
                  <h2>
                    <AccordionButton className='bg-slate-100 hover:bg-slate-200 px-3'>
                      <div className='inline-flex gap-4 w-full text-left text-sm font-semibold py-1 uppercase'>
                        <Icon as={FaCircleCheck} boxSize={4} />
                        Cancellation Fees
                      </div>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel className='border-t py-4'>
                    {/* Options */}
                    <div>
                      ReLease will cover any cancellation fees owed due to a
                      covered cancellation, up to your coverage limit.
                    </div>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem className='border-none'>
                  <h2>
                    <AccordionButton className='bg-slate-100 hover:bg-slate-200 px-3'>
                      <div className='inline-flex gap-4 w-full text-left text-sm font-semibold py-1 uppercase'>
                        <Icon as={FaCircleCheck} boxSize={4} />
                        Lease Buyout Fees
                      </div>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel className='border-t py-4'>
                    {/* Options */}
                    <div>
                      If available, ReLease may cover the lease buyout fees
                      specified in your lease in lieu of Outstanding Rent and
                      Cancellation Fees, up to your coverage limit.
                    </div>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Flex>
            <Flex className='flex-col w-full gap-4 justify-start'>
              <h2 className='text-xl font-bold'>What's Not Covered</h2>
              <Accordion
                allowMultiple
                className='flex w-full flex-col border border-slate-200 rounded-md overflow-hidden'
              >
                <AccordionItem className='border-none'>
                  <h2>
                    <AccordionButton className='bg-slate-100 hover:bg-slate-200 px-3'>
                      <div className='inline-flex gap-4 w-full text-left text-sm font-semibold py-1 uppercase'>
                        <Icon as={FaCircleXmark} boxSize={4} />
                        Leasing Concessions
                      </div>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel className='border-t py-4'>
                    {/* Options */}
                    <div>
                      ReLease doesn't cover any leasing concessions, incentives,
                      discounts, or offers that you have to repay as part of a
                      cancellation.
                    </div>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem className='border-none'>
                  <h2>
                    <AccordionButton className='bg-slate-100 hover:bg-slate-200 px-3'>
                      <div className='inline-flex gap-4 w-full text-left text-sm font-semibold py-1 uppercase'>
                        <Icon as={FaCircleXmark} boxSize={4} />
                        Security Deposts
                      </div>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel className='border-t py-4'>
                    {/* Options */}
                    <div>
                      ReLease doesn't cover any security deposits or security
                      deposit replacement fees that you may have deducted as
                      part of a cancellation.
                    </div>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Flex>
          </Flex>
        </Card>
      )}

      {/* exclusions */}
      {exclusions && (
        <Card
          border='1px solid #E1E6EF'
          className='flex flex-col gap-4 px-6 py-8 rounded-lg'
        >
          <Flex className='flex-col gap-2'>
            <h2 className='text-2xl font-bold'>Coverage Exclusions</h2>
            <Text className='mb-4'>
              Your ReLease plan covers cancellations for any reason, including job
              changes, home purchases, illness, and more, with a few exceptions.{' '}
              <AnchorLinkTag
                className='underline'
                hrefPath='https://www.releaserent.com/legal/lease-flexibility-coverage-terms-conditions'
              >
                Learn more about what's covered in your plan
              </AnchorLinkTag>
              .
            </Text>
          </Flex>

          {/* plan heading and status */}
          <Flex className='flex mobile:flex-col gap-8 w-full'>
            <Flex className='flex-col w-full gap-4 justify-start'>
              <h2 className='text-xl font-bold'>Examples of Covered Cancellations</h2>
              <Accordion
                allowMultiple
                className='flex w-full flex-col border border-slate-200 rounded-md overflow-hidden'
              >
                <AccordionItem className='border-none'>
                  <h2>
                    <AccordionButton className='bg-slate-100 hover:bg-slate-200 px-3'>
                      <div className='inline-flex gap-4 w-full text-left text-sm font-semibold py-1 uppercase'>
                        <Icon as={FaCircleCheck} boxSize={4} />
                        Job Changes
                      </div>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel className='border-t py-4'>
                    {/* Options */}
                    <div>
                      ReLease covers your cancellation if you need to move as
                      part of a job relocation, whether it's for your current
                      employer or for a new employer you're starting at.
                    </div>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem className='border-none'>
                  <h2>
                    <AccordionButton className='bg-slate-100 hover:bg-slate-200 px-3'>
                      <div className='inline-flex gap-4 w-full text-left text-sm font-semibold py-1 uppercase'>
                        <Icon as={FaCircleCheck} boxSize={4} />
                        Home Purchase
                      </div>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel className='border-t py-4'>
                    {/* Options */}
                    <div>
                      Thinking about buying a new home but not sure when it'll
                      close? ReLease covers your cancellation if you're
                      cancelling mid-lease to purchase a new home.
                    </div>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem className='border-none'>
                  <h2>
                    <AccordionButton className='bg-slate-100 hover:bg-slate-200 px-3'>
                      <div className='inline-flex gap-4 w-full text-left text-sm font-semibold py-1 uppercase'>
                        <Icon as={FaCircleCheck} boxSize={4} />
                        School Transfers
                      </div>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel className='border-t py-4'>
                    {/* Options */}
                    <div>
                      If you're a student transfering schools or leaving school
                      altogether, ReLease covers your cancellation so you're not
                      stuck paying for two apartments at the same time.
                    </div>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Flex>
            <Flex className='flex-col w-full gap-4 justify-start'>
              <h2 className='text-xl font-bold'>Excluded Cancellations</h2>
              <Accordion
                allowMultiple
                className='flex w-full flex-col border border-slate-200 rounded-md overflow-hidden'
              >
                <AccordionItem className='border-none'>
                  <h2>
                    <AccordionButton className='bg-slate-100 hover:bg-slate-200 px-3'>
                      <div className='inline-flex gap-4 w-full text-left text-sm font-semibold py-1 uppercase'>
                        <Icon as={FaCircleXmark} boxSize={4} />
                        Pre-Meditated Actions
                      </div>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel className='border-t py-4'>
                    {/* Options */}
                    <div>
                      ReLease can't cover any cancellations due to premeditated
                      actions, including but not limited to actions, decisions,
                      or situations that you intentionally plan or consciously
                      execute with prior, undisclosed knowledge before purchase.
                    </div>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem className='border-none'>
                  <h2>
                    <AccordionButton className='bg-slate-100 hover:bg-slate-200 px-3'>
                      <div className='inline-flex gap-4 w-full text-left text-sm font-semibold py-1 uppercase'>
                        <Icon as={FaCircleXmark} boxSize={4} />
                        Landlord Cancellations
                      </div>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel className='border-t py-4'>
                    {/* Options */}
                    <div>
                      ReLease can't cover any cancellation or termination of the
                      lease agreement by your landlord for any reason, including
                      the result of a force majeure event.
                    </div>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem className='border-none'>
                  <h2>
                    <AccordionButton className='bg-slate-100 hover:bg-slate-200 px-3'>
                      <div className='inline-flex gap-4 w-full text-left text-sm font-semibold py-1 uppercase'>
                        <Icon as={FaCircleXmark} boxSize={4} />
                        Unlawful Tenancies
                      </div>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel className='border-t py-4'>
                    {/* Options */}
                    <div>
                      ReLease can't cover any cancellations if your lease is
                      found to be illegal at any point during the coverage
                      period, or any other circumstances where your tenancy is
                      deemed unlawful, including if you or another resident is
                      lawfully evicted.
                    </div>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Flex>
          </Flex>
        </Card>
      )}
    </>
  );
};

export default DisclosureCard
